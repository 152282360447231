@import "../styles/vars.scss";

.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.closeIcon{
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
}

.modalOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
}

.modalContent{
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 70rem;
    max-height: 90%;
    overflow: auto;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);

    
    img{
        width: 100%;
        max-height: 50rem;
        object-fit: contain;
    }
}

@media screen and (min-width: 70rem){
    .modalContent{
        border-radius: 1rem;
    }
} 
