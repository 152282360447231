$small-text-size: 1.2rem;
$text-size: 1.6rem;
$big-text-size: 1.8rem;
$subtitle-size: 1.8rem;
$title-size: 2.6rem;

$heade-padding: 2.4rem;
$header-height: 6.5rem;
$page-horizontal-padding: 3rem;
$smallpage-horizontal-padding: 2rem;
$footer-height: 11.6rem;
