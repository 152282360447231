$primary-color: white;
$secondary-color: #888888;
$unselected-color: #DDDDDD;
$bg-color: #53565A;
$bg-alt-color: white;
$bg-white: #000000;
$orange-500: #F15D12;
$grey-700: #999;
$main-button-bg: #3A3C3F;
$main-text-color: #FFFFFF;
$grey-300: #CBCCCE;
$header-color: #000;