@import "../styles/vars.scss";

.wrapper {
    position: fixed;
    width: 100vw;
    height: $footer-height;
    bottom: 0;
    z-index: 100;
    padding: 2rem;
    font-family: $secondary-font-family;
    font-size: $text-size;
    transition: transform .25s;
    transform: translateY(100%);
}

.seekControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
}

.currentTime {
    font-weight: $bold;
    min-width: 3.5rem;
}

.slider {
    margin: 0 2rem;
}

.playWrapper {
    display: flex;
    flex-direction: row;
}

.play {
    $size: 4.8rem;
    width: $size;
    height: $size;
    margin-right: 1.5rem;
}

.title {
    font-size: $subtitle-size;
    font-weight: $semibold;
}

.subtitle {
    color: $secondary-color;
    font-weight: $medium;
}

.closeButton {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}

.closeIcon {
    $size: 2.4rem;
    width: $size;
    height: $size;
}
