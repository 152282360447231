@import "../styles/vars.scss";


.page{
    text-align: center;
    padding-left: $smallpage-horizontal-padding;
    padding-right: $smallpage-horizontal-padding;
}

.logo{
    width: 16rem;
    margin: 4rem auto 3.2rem auto;
    filter: invert(1);
}

.carouselImg{
    width: 100%;
    height: 25rem;
    object-fit: cover;
    background-color: #53565A;
}

.carCircle{
    margin-top: -40%;
    height: 24rem;
    width: 24rem;
}

.carDescription{
    text-align: left;
    font-family: $secondary-font-family;
    font-weight: $medium;
    font-size: $text-size;
    line-height: 2.2rem;
}

.showMore{
    color: #CBCCCE;
    font-size: 14px;
    text-decoration: underline;
    margin-top: 6px;
}

.tecnicalDataElement{
    padding-bottom: 2rem;
}
.tecnicalDataTitle{
    font-family: $secondary-font-family;
    font-weight: $bold;
    font-size: $big-text-size;
    margin-bottom: .8rem;
    text-transform: uppercase;
    text-align: center;
}

.tecnicalDataValue{
    max-width: 90%;
    font-family: $secondary-font-family;
    font-size: $text-size;
    line-height: 2.2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    text-align: center;

}

.brandName{
    font-weight: $bold;
    font-size: $title-size;
    margin-bottom: .8rem;
    text-transform: uppercase;
    text-align: center;
}

.partner{
    font-family: $secondary-font-family;
    font-size: 20px;
    text-align: left;
    font-weight: $bold;
}

.partnerTitleContainer{
    display: flex;

    .partnerTitle{
        width: 100%;
        font-family: $secondary-font-family;
        font-size: 16px;
        text-transform: uppercase;
        padding: 12px 6px;
    }

    .selectedPartner{
        font-weight: $bold;
        border-bottom: 2px solid #ffffff;
    }
}

.partnerDescription{
    text-align: left;
    font-size: $text-size;
    line-height: 2.2rem;
    font-family: $secondary-font-family;
}

.subtitle{
    text-align: left;
    font-size: 20px;
    font-weight: $bold;
}

.partnerTitle{
    font-family: $secondary-font-family;
    font-weight: $bold;
    font-size: $big-text-size;
    // margin-bottom: 3rem;
    text-align: center;
}