@charset "UTF-8";

/* http://clubmate.fi/scss-font-face-mixin/ */

@mixin font-face($style-name, $file, $family, $fontweight, $fontstyle, $category:"") {
    $filepath: "../assets/fonts/" + $family + "/" + $file;
    @font-face {
        font-family: "#{$style-name}";
        src:url($filepath + ".eot");
        src:
            url($filepath + ".eot?#iefix") format('embedded-opentype'),
            url($filepath + ".woff") format('woff'),
            url($filepath + ".ttf")  format('truetype'),
            url($filepath + ".svg#" + $style-name + "") format('svg');
        font-weight: $fontweight;
        font-style: $fontstyle;
    }
    %#{$style-name} {
        font: {
            @if $category != "" {
                font-family: "#{$style-name}", #{$category};
            }
            @else {
                font-family: "#{$style-name}";
                font-weight: normal;
            }
        }
    }
}


/* @include font-face($style-name, $file, $family, $fontweight, $fontstyle, $category);  */
/* @include font-face(NOME, FILE, CARTELLA, PESO, STYLE, SERIF);  */


// Palatino
@include font-face("Palatino", "Palatino", "Palatino", 400, normal, "sans serif");
@include font-face("Palatino", "Palatino-italic", "Palatino",  400, italic, "sans serif");

// Roboto
@include font-face("Roboto Condensed", "Roboto Condensed", "Roboto",  400, normal, "sans serif");
@include font-face("Roboto Condensed", "RobotoCondensed-Light", "Roboto",  300, normal, "sans serif");
@include font-face("Roboto Condensed", "RobotoCondensed-Bold", "Roboto",  700, bold, "sans serif");


