@import "../styles/vars.scss";

.header {
    position: fixed;
    width: 100vw;
    height: $header-height;  // TODO mai: su desktop è troppo alto
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    background-color: $header-color;
    z-index: 100;
}

.alt {
    background-color: $primary-color;
    color: $bg-color;
    border-bottom-color: $primary-color;
}

.logo {
    position: absolute;
    width: 15rem;
    left: 50%;
    transform: translateX(-50%);
    filter: invert(1);
}

.imgButton {
    width: 2.4rem;
    height: 2.4rem;

    &.imgButtonAlt {
        filter: invert(100%);
    }
}

.buttonHeader {
    color: $main-text-color;
}
