@import "../styles/vars.scss";

.page{
    padding-top: calc(4rem + $header-height);
    padding-bottom: 13rem;
    padding-left: $smallpage-horizontal-padding;
    padding-right: $smallpage-horizontal-padding;
}

.sectionTitle{
    margin-bottom: .4rem;
    font-family: $secondary-font-family;
    font-size: $text-size;
    text-transform: uppercase;
    color: $grey-300;
}

.welcomeIT{
    font-family: $secondary-font-family;
    font-size: $text-size;
}

.welcomeEN{
    font-family: $primary-font-family;
    font-style: italic;
    font-size: $text-size;
}

.title{
    font-family: $secondary-font-family;
    font-size: 3.2rem;
    margin-bottom: 1.6rem;
    font-weight: 500;
    text-align: center;
}

.coverImg{
    width: 100%;
    margin-bottom: 1.6rem;
}
.videoCoverImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.eventDetail{
    font-family: $secondary-font-family;
    text-decoration: underline;
    font-size: $text-size;
    color: $grey-300;
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    &:hover{
        color: $main-text-color;
    }
}

.button {
    font-size: 3.6rem;
    margin: 1.5rem 0;
}

.button:first-of-type {
    margin-top: 4rem;
}

.button:last-of-type {
    margin-bottom: 10.9rem;
}

.languageButtons{
    width: 100%;
    margin-top: 12rem;
}

.smallTitle{
    font-family: $secondary-font-family;
    font-size: $big-text-size;
    margin-bottom: .8rem;
}

.eventText{
    font-family: $secondary-font-family;
    font-size: $text-size;
    margin-bottom: 2.1rem;
    line-height: 2.2rem;
}