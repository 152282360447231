@import "../styles/vars.scss";


.header{
    position: relative;
    // size used to go over the page padding without putting the element absolute
    width: calc(100% + $smallpage-horizontal-padding + $smallpage-horizontal-padding);
    margin-left: -$smallpage-horizontal-padding;
    margin-bottom: 4rem;

    &.headerPictureless{
        height: 9.5rem;
        background-color: $main-button-bg;

        .headerTitle{
            background-color: transparent;
        }
    }
}

// .headerTitle covers the whole pararent with a background color and a centered text
.headerTitle{
    width: 100%;
    height: 107%; // if the title is not on 2 lines turn back to 100%
    background-color: #000000CC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $secondary-font-family;
    font-weight: $light;
    font-size: 2.4rem;
    position: absolute;
    text-align: center;
}

.welcome{
    margin: 0;
}

.welcomeIT{
    font-family: $secondary-font-family;
    font-size: $text-size;
}

.welcomeEN{
    font-family: $primary-font-family;
    font-style: italic;
    font-size: $text-size;
}


.title{
    font-family: $secondary-font-family;
    font-size: 3.2rem;
    margin-bottom: 1.6rem;
    font-weight: 500;
}

.coverImg{
    width: 100%;
    height:9.5rem;
    object-fit: cover;
}