@import "../styles/vars.scss";

.slider {
    width: 100%;
}

.thumb {
    cursor: pointer;
    background: $bg-color;
    top: -7px;
    /* shape of the thumb: circle */
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
}
