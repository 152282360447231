@import "../styles/vars.scss";

.wrapper {
    width: 100%;
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 9rem;
    margin-top: 6rem;
    margin-left: 0!important;
}

.icon {
    margin-left: 2rem;
}

.website{
    color: $main-text-color;
    font-size: $text-size;
    font-family: $secondary-font-family;
    text-decoration: none;
}

.socialWrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.link{
    font-family: $secondary-font-family;
    margin: 0;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;
}