@import "../styles/vars.scss";

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $secondary-font-family;
    font-weight: $light;
    margin-bottom: 2rem;
    background-color: $main-button-bg;
    position: relative;

    .box {
        padding: 9rem 2rem;
    }
}

.title {
    font-weight: bold;
    font-size: 2.4rem;   
}

.subtitle {
    font-size: $big-text-size;
    font-family: $secondary-font-family;
    font-weight: $light;
    color: $grey-700;
}


.divNext {
    position: absolute;
    bottom: 1.6rem;
    right: 2rem;
}
