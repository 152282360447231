@import "../styles/vars.scss";

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.welcomeIT{
    font-family: $secondary-font-family;
    font-size: $text-size;
}

.welcomeEN{
    font-family: $primary-font-family;
    font-style: italic;
    font-size: $text-size;
}

.title{
    font-family: $secondary-font-family;
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
    font-weight: 500;
    text-align: center;
}

.coverImg{
    width: 100%;
    margin-bottom: 1.6rem;
}

.eventDetail{
    font-family: $secondary-font-family;
    margin-bottom: 12rem;
    text-decoration: underline;
    font-size: $text-size;
    color: $grey-300;
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    &:hover{
        color: $main-text-color;
    }
}

.button {
    font-size: 3.6rem;
    margin: 1.5rem 0;
}

.button:first-of-type {
    margin-top: 4rem;
}

.button:last-of-type {
    margin-bottom: 10.9rem;
}
