@import "../styles/vars.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $secondary-font-family;
    margin-bottom: 3rem;
    background-color: $main-button-bg;
    position: relative;
    padding: 1.8rem 1.6rem;
    width: 100%;
    // make non selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome and Opera */

    .box {
        width: 100%;
        min-height: 21rem + 2.3rem;
        padding: 2rem;
    }

    &.selected{
        filter: brightness(1.5);
    }
}

.title {
    font-size: $big-text-size;   
    color: $main-text-color;
    text-transform: uppercase;
}


.arrow {
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
}
