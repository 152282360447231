@import "./styles/vars.scss";

body {
    overscroll-behavior: none;
}

html {
    font-size: 62.5%;

    @media (min-width: $breakpoint-tablet) {
        font-size: 80%;
    }

    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $primary-color;
    font-family: $primary-font-family;
    font-weight: $regular;
    font-size: $small-text-size;
    overflow-x: hidden;
}

.title {
    font-family: $secondary-font-family;
    font-weight: $semibold;
    font-size: $title-size;
    margin: 0;
    margin-bottom: 3rem;
}

.subtitle {
    font-family: $secondary-font-family;
    font-weight: $medium;
    font-size: $subtitle-size;
    color: $secondary-color;


    &.page-subtitle {
        margin: 0;
        margin-bottom: 2.5rem;
        color: $grey-700;
    }
}

.text {
    font-size: $text-size;
}

.alt-color {
    color: $bg-color;
}

.alt-all {
    color: $bg-color;
    background-color: $bg-alt-color;
}

.unselected {
    color: $unselected-color;
}

.page {
    width: 100vw;
    background-color: $bg-color;
    padding: ($header-height + 5rem) $page-horizontal-padding 3rem $page-horizontal-padding;

    &.alt-color {
        background-color: $bg-alt-color;
    }

    &.whiteBg {
        background-color: $bg-white;
    }

    &.center-align {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &.top-p-header {
        padding-top: $header-height;
    }

    &.small-horizontal-padding {
        padding-left: $smallpage-horizontal-padding;
        padding-right: $smallpage-horizontal-padding;
    }

    &.timeline-container {
        padding-left: 0;
        padding-top: $header-height;
        padding-bottom: 0;
    }
}


button {
    border: none;
    background-color: inherit;
    color: $primary-color;
    font-family: $secondary-font-family;
    margin: 0;
}


audio {
    display: none;
}

.slider-track {
    /* Top value to align your track to the center of your thumb */
    top: -3px;
    height: 2px;
    background: $unselected-color;
}

.slider-track.slider-track-0 {
    /* color of the track before the thumb */
    background: $bg-color;
}

.page-box {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;

    &.partner {
        min-height: calc(100vh - 295px);
    }

    &.error {
        min-height: calc(100vh - 95px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1, h3 {
            text-align: center;
        }
    }

    &.loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100dvh - 295px);
    }
}

.swiper-overflow {
    overflow: visible !important;
}

.carousel-navigation-wrapper {
    display: flex;
    justify-content: space-between;
}

.swiper-button-prev,
.swiper-button-next {
    /* margin-top: 10rem; */
    position: relative !important;
    background-color: #3A3C3F;
    height: 5.6rem !important;
    width: 5.6rem !important;

    &:after {
        display: none;
        font-size: 2.5rem;
    }
}

.swiper-button-prev {
    img {
        transform: rotate(180deg);
    }
}

.swiper-pagination {
    transform: translateY(-50%) !important;
    display: flex;
    justify-content: center;
}

// .history-detail-container .swiper-slide {
//     width: auto !important;
// }

.wrapper {
    position: relative;
    margin: 0 0 0 80px;
    width: 80%;
    //border-left: 1px solid #fff;

    .milestone-wrapper {
        opacity: 0.1;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        padding-top: 50px;
        padding-left: 24px;

        .swiper-pagination{
            position: relative;
            width: auto;
            text-align: left;
        }

        .swiper-pagination-bullet{
            height: 10px;
            width: 10px;
            opacity: 1;
            background-color: #C8C9C7;
        }

        img {
            width: 100%;
            height: auto;
            max-width: 80%;
        }

        &.active {
            opacity: 1;
            pointer-events: all;

            .swiper-slide img{
                max-width: 100%;
            }

            .timeline-desription{
                max-width: 100%;
            }
        }

        .swiper-pagination-bullet-active {
            background-color: #fff !important;
        }
        
        .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
            bottom: 0;
        }
        
        .swiper-slide{
            padding-bottom: 4px;
            margin: 0 auto;

            img{
                box-shadow: rgba(17, 12, 46, 0.04) 0px 48px 100px 0px;
            }
        }

        .swiper-pagination{
            padding-bottom: 8px;
        }
    }

    @media screen and (max-width: 768px) {
        .milestone-wrapper:first-child {
            padding-top: 20vh;
        }
    }

    .milestone {
        margin: 0;
    }

    .milestone-wrapper:last-child {
        padding-bottom: 50vh;
    }
}

article {
    position: relative;
    max-width: 980px;
    margin: 0 0;
}

.timeline {
    &__nav {
        position: fixed;
        z-index: 99;
        top: 0;
        transition: top .3s ease-out;
        margin-left: 0;

        ul {
            list-style: none;
            list-style-position: inside;
            margin: 15px 0;
            padding: 20px 20px 20px 0px;

            li {
                margin: 22px 8px 22px 0;
                padding-left: 0;
                padding-right: 8px;
                list-style-type: none;
                line-height: 1;
                color: #888888;
                cursor: pointer;
                transition: all .3s ease-out;
                position: relative;
                width: 70px;
                text-align: right;
                font-size: 10px;

                .circle{
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: #fff;
                    display: inline-block;
                    position: absolute;
                    left: 76.5px;
                    transition: all .3s ease-out;
                    top: 1px;
                }

                .timeline-point{
                    position: absolute;
                    opacity: 0;
                }

                &.active {
                    font-weight: bold;
                    color: #fff;
                    border-bottom: 0;
                    font-size: 16px;

                    .circle{
                        width: 0;
                        height: 0;
                    }

                    .timeline-point{
                        opacity: 1;
                        top: -1px;
                        right: -16px;
                        width: 14px;
                        height: 14px;
                        transition: opacity .5s ease-in;
                    }
                }
            }
        }
    }
}


.timeline-bg {
    z-index: 0;
    left: 50%;
    top: 40%;
    transform: translate(-55%, -50%);
    width: 100vw;
    height: auto;
    max-width: 400px;
    position: fixed;
    transition: all .3s ease-out;
}

.timeline-wrapper {
    max-width: 600px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.timeline-desription{
    max-width: 80%;
    font-size: 15px;
    margin: 8px 0;
    font-family: $secondary-font-family;
}

.wrapper .milestone-wrapper .swiper-horizontal > .swiper-pagination-bullets, .wrapper .milestone-wrapper .swiper-pagination-bullets.swiper-pagination-horizontal, .wrapper .milestone-wrapper .swiper-pagination-custom, .wrapper .milestone-wrapper .swiper-pagination-fraction{
    bottom: -10px;
    width: auto;
}

.timeline-line{
    position: fixed;
    width: 1px; 
    background-color: #fff;
    height: calc(100vh - 65px);
    top: 65px;
    left: 78.5px
}

.lds-dual-ring {
    /* change color here */
    color: #ffffff;
  }
  .lds-dual-ring,
  .lds-dual-ring:after {
    box-sizing: border-box;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6.4px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }