@import "../styles/vars.scss";


.wrapper{
    margin-bottom: 2.4rem;
    display: flex;
    justify-content: center;
}

.imgWrapper{
    position: relative;
    margin-bottom: 1.2rem;
    line-height: 0;
    display: flex;
    justify-content: center;
    width: 70%;
}
.img{
    width:100%;
    box-shadow: 0 1.2rem 2.4rem #0000004D;
    object-fit: contain;
    aspect-ratio: 2.1;

    &.no-shadow{
        box-shadow: none;
    }
}

.name{
    font-size: $big-text-size;
    font-family: $secondary-font-family;
    text-align: center;
    margin: 0;
}

.arrowContainer{
    position: absolute;
    bottom: 0;
    right: 5rem;
    height: 2.6rem;
    width: 2.6rem;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: .3rem;
    background-color: rgba(0, 0, 0, 0.8);
    width: 2.6rem;
    height: 2.6rem;
}